import { Datepicker } from 'vanillajs-datepicker';
import LocaleFr from 'vanillajs-datepicker/locales/fr';

Datepicker.locales.fr = LocaleFr.fr;

document.addEventListener("DOMContentLoaded", function () {
  for(const elem of document.querySelectorAll('.date')) {
    new Datepicker(
      elem, {
        format: 'yyyy-mm-dd',
        language: 'fr',
        minDate: elem.dataset.mindate,
        maxDate: elem.dataset.maxdate
      }
    );
  }

  for(let elem of document.querySelectorAll('.datepicker-future-disabled')) {
    new Datepicker(
      elem, {
        format: 'yyyy-mm-dd',
        maxDate: new Date(),
        language: 'fr'
      }
    );
  }
});