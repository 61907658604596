const Trix = require("trix");
require("@rails/actiontext");

Trix.default.config.textAttributes.underline = {
  tagName: 'u',
  inheritable: true
}

// Remove the file button
document.addEventListener("DOMContentLoaded", function () {
  /*
   * We do not want the Trix's attachment feature
   */
  document.querySelectorAll(".trix-button-group--file-tools").forEach((e) => {
    e.remove();
  });
});

//Initialize all button to add
document.addEventListener("trix-initialize", (e) => {
  let btnUnderline = '<button type="button" class="trix-button underline" data-trix-attribute="underline"><i class="fa-solid fa-underline fa-lg"></i></button>'
  e.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", btnUnderline)

  // Reload HTML otherwise underline is not taken into account.
  if (e.target.dataset.text) {
    e.target.editor.loadHTML(e.target.dataset.text);
  }
});

// Prevent the trix-file-accept event that still may fire on paste or drop
document.addEventListener("trix-file-accept", function (e) {
  e.preventDefault()
});