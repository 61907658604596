require("@rails/ujs").default.start();
require("@rails/activestorage").start();

require('@popperjs/core');
require('bootstrap');
require('@fortawesome/fontawesome-free/js/all');

require('./theme/main');
require('./libs/datepicker');
require('./libs/tomselect');
require('./libs/trix');
require('./libs/masking-input');
require('./utils/hideable-field');